import React from "react";
import { NavLink } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="center-container">
      <h1 className="title">HHMSSWIN</h1>
      <a
        className="download-button"
        href="https://drive.google.com/file/d/1ifbHzXqP3e7sAkivmgq2o3LhBV5Np1nv/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download App
      </a>
    </div>
  );
}

export default App;
